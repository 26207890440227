.upload-container {
  max-width: 32rem;
  padding: 1rem;
  background: rgba(40, 44, 52, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  max-height: 50vh;
  overflow: hidden; /* Ensure the container itself clips content */
  display: flex;
  flex-direction: column;
}

.preview-container {
  width: 100%;
  max-width: 20rem;
  height: 30vh; /* Set explicit height instead of max-height */
  overflow: hidden;
  position: relative; /* Add positioning context */
}

.preview-image {
  width: 100%;
  height: 100%; /* Change to 100% to fill container */
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}
.upload-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  background-image: linear-gradient(90deg, #ff6b6b, #4ecdc4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  overflow: hidden;
  max-height: 20vh;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.file-input {
  display: none;
}

.file-input-label {
  padding: 0.5rem 1.5rem;
  background: linear-gradient(45deg, #4ecdc4, #45b7af);
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.875rem;
}

.file-input-label:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(78, 205, 196, 0.3);
}

.error-message {
  color: #ef4444;
  font-size: 0.875rem;
  background-color: rgba(239, 68, 68, 0.1);
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  width: 100%;
  text-align: center;
}

.upload-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  cursor: pointer;
  background: linear-gradient(45deg, #ff6b6b, #ff5252);
  transition: all 0.3s ease;
}

.upload-button:not(.disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 107, 107, 0.3);
}

.upload-button.disabled {
  background: #9ca3af;
  cursor: not-allowed;
  opacity: 0.7;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #3b82f6;
}

.spinner {
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;
}

.spinner.large {
  width: 2rem;
  height: 2rem;
}

.result-container {
  padding: 1rem;
  background: rgba(120, 120, 128, 0.8);
  border-radius: 12px;
  width: 100%;
}

.result-text {
  font-size: 1.125rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add some fun hover effects */
.result-container {
  transition: all 0.3s ease;
}

.result-container:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(78, 205, 196, 0.2);
}

/* Add the glitch effect on hover for the title */
.upload-title:hover {
  animation: glitch 0.3s infinite;
}

.content-wrapper {
  display: flex;
  flex-wrap: column wrap;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: start;
}

.result-container.empty {
  background: rgba(243, 244, 246, 0.4);
  color: #6b7280;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }
}
.upload-container,
.result-wrapper {
  width: 100%;
}
