.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center; /* Centers the projects horizontally */
  max-width: 1200px; /* Limits the container width for better readability */
  margin: 0 auto; /* Centers the container on the page */
  max-height: calc(
    100vh - 500px
  ); /* Subtract space for header, tabs, and padding */
  margin-top: 200px;
}
.project-header {
  position: absolute;
  top: 50px; /* This accounts for the nav bar height + some padding */
  left: 0;
  right: 0;
  z-index: 2;
}

.project-card {
  transition: all 0.3s ease;
}

.project-card h3 {
  margin-bottom: 1rem; /* Adds space below the title */
  font-size: 1.5rem; /* Larger font size for titles */
  color: #4ecdc4; /* Accent color for titles */
}

.project-card p {
  font-size: 1rem; /* Standard font size for descriptions */
  color: rgba(255, 255, 255, 0.8); /* Slightly transparent text */
}
@media (max-width: 768px) {
  .projects-container {
    padding: 1rem;
  }

  .project-card {
    max-width: 100%; /* Full width on mobile */
  }
}
.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab-button {
  background: rgba(184, 180, 180, 0.863);
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.tab-button.active {
  background: #4ecdc4;
  color: white;
  box-shadow: 0 4px 10px rgba(255, 107, 107, 0.3);
}
