/* Aboutme.css */

/* General Styles */
.about-header {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center; /* Center the header text */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 20px;
  padding-top: 150px; /* Adjust as needed for spacing */
}

.profile-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: center; /* Center items horizontally */
  align-items: center;
  gap: 20px; /* Add space between image and text */
  margin-bottom: 20px; /* Add space below the profile section */
}

.profile-text {
  max-width: 400px; /* Limit text width for readability */
  text-align: center; /* Center text */
  font-size: 18px; /* Adjust font size */
  color: #a0a0a0;
  margin-bottom: 3px;
  text-align: left;
  min-width: 25vw;
}

.photo {
  width: 256px;
  height: 256px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.about-left,
.about-right {
  flex: 1;
  min-width: 300px; /* Prevent columns from becoming too narrow */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  backdrop-filter: blur(5px);
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #e0e0e0;
}

.skills-grid,
.interests-grid {
  display: grid;
  gap: 15px;
}

.skill-group,
.interest-group {
  margin-bottom: 10px;
}

.skill-title,
.interest-title {
  font-size: 14px;
  font-weight: 500;
  color: #e0e0e0;
  margin-bottom: 5px;
}

.skill-items,
.interest-item {
  font-size: 13px;
  color: #a0a0a0;
  margin-bottom: 3px;
}

.contact-section {
  margin-top: 20px;
  text-align: center;
}

.contact-button {
  display: inline-block;
  font-size: 14px;
  padding: 8px 16px;
  background-color: #3182ce;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.contact-button:hover {
  background-color: #2c5282;
}

@media (max-width: 768px) {
  .content-container {
    padding-top: 100px; /* Reduce padding for smaller screens */
  }

  .profile-container {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center items horizontally */
  }

  .profile-text {
    padding-left: 0; /* Remove left padding */
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .about-left,
  .about-right {
    min-width: 100%; /* Make columns take full width on small screens */
  }

  .section-title {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .skill-title,
  .interest-title {
    font-size: 13px; /* Adjust font size for smaller screens */
  }

  .skill-items,
  .interest-item {
    font-size: 12px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .photo {
    width: 200px; /* Reduce image size for very small screens */
    height: 200px;
  }

  .profile-text {
    font-size: 14px; /* Further reduce font size for very small screens */
  }
}
