.chatbot-container {
  display: flex;
  flex-direction: column;
  flex: 6;
  border-radius: 0 12px 12px 0;
  background-color: rgba(40, 44, 52, 0.9);
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}
.chatbot-sidebar-container {
  display: flex;
  margin-left: 20px;
  height: 25vw;
  width: 55vw;
}
.messages-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 70%;
  padding: 12px 16px;
  margin-bottom: 12px;
  border-radius: 12px;
  animation: messagePopIn 0.3s ease-out;
  font-size: 1rem;
}

.user-message {
  background: linear-gradient(45deg, #4ecdc4, #45b7af);
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 4px;
  font-size: 1rem;
}

.bot-message {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  align-self: flex-start;
  border-bottom-left-radius: 4px;
}

.input-form {
  display: flex;
  padding: 16px;
  gap: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.message-input {
  flex: 1;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.3s ease;
}

.message-input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 0 2px rgba(78, 205, 196, 0.3);
}

.message-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.send-button {
  padding: 12px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(45deg, #ff6b6b, #ff5252);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}
.upload-files-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  width: auto;
  margin: 0.5rem;
  color: #4ecdc4;
  border: none;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.upload-files-btn:hover {
  background-color: rgba(78, 205, 196, 0.1);
}
.send-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 107, 107, 0.3);
}

.send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading-dots {
  display: flex;
  gap: 4px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  animation: bounce 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes messagePopIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}
.upload-section {
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.uploaded-files-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.uploaded-file-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  animation: slideIn 0.2s ease-out;
}

.file-name {
  flex: 1;
  font-size: 0.9rem;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.remove-file-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.remove-file-btn:hover {
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}
.text-red-500 {
  color: rgb(239, 68, 68);
}
