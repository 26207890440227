.App {
  text-align: center;
  background: linear-gradient(180deg, #fc6363, #48ebe0, #1855fc);
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto; /* Ensure vertical scrolling works */
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
}

.App-link:hover {
  color: #ff6b6b;
  text-shadow: 0 0 10px rgba(255, 107, 107, 0.5);
}

.App-link::after {
  content: "👈";
  position: absolute;
  right: -30px;
  opacity: 0;
  transition: all 0.3s ease;
}

.App-link:hover::after {
  opacity: 1;
  transform: translateX(10px);
}
.section {
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 44, 52, 0.9);
  backdrop-filter: blur(5px);
  position: relative;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Footer Styling */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(40, 44, 52, 0);
  text-align: center;
  z-index: 1000;
}

.footer-text {
  font-size: 11px;
  margin: 0;
  opacity: 0.7;
  color: aliceblue;
}

.footer-subtext {
  font-size: 9px;
  opacity: 0.5;
  margin-top: 5px;
}

/* Fun elements and animations */
.emoji-rain {
  position: fixed;
  top: -20px;
  font-size: 20px;
  animation: fall linear infinite;
  z-index: 0;
}

.glitch-text {
  position: relative;
  transition: all 0.3s ease;
}

.glitch-text:hover {
  text-shadow: 2px 2px #ff6b6b, -2px -2px #4ecdc4;
  animation: glitch 0.3s infinite;
}

.rainbow-text {
  background-image: linear-gradient(90deg, #ff6b6b, #4ecdc4, #ff6b6b);
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow 3s linear infinite;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(30px) rotate(10deg);
  }
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes rainbow {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
}
.App::before {
  content: "🤪";
  position: fixed; /* Changed to fixed */
  font-size: 200px;
  opacity: 0.1;
  animation: floating 10s ease-in-out infinite;
  z-index: 1;
  right: -50px;
  top: 50px;
}

/* Navigation styles */
.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(40, 44, 52, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav-link:hover,
.nav-link.active {
  color: #ff6b6b;
  text-shadow: 0 0 10px rgba(255, 107, 107, 0.5);
}

.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ff6b6b;
  transition: width 0.3s;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}
